import { colors } from "../ui/colors";
import styles from './home.module.css';
import Image1 from '../ui/images/phone1.png'
import Image2 from '../ui/images/phone2.png'
import Image3 from '../ui/images/phone3.png'
import Image4 from '../ui/images/phone4.png'
import WatchImage1 from '../ui/images/watch1.png'
import WatchImage2 from '../ui/images/watch2.png'
import WatchImage3 from '../ui/images/watch3.png'
import AppStore from '../ui/images/appstore.svg'

import { LayoutType, Section, SectionDropdown } from "../ui/section";

export default function Home() {
  return (
    <>

        <Section padding={false}>
            <div style={{width: '70%', textAlign: 'center', padding: '54px'}}>
                <div className={styles.heading1}>
                    Habbie
                </div>
                <div className={styles.subHeading}>
                    Take care of your Habbie
                    <br />
                    & form your real life habits!
                </div>
                <div className={styles.downloadButton}>
                <a href="https://apps.apple.com/app/apple-store/id6468639991?pt=122400551&ct=website&mt=8" 
                    style={{
                        display: "inline-block",
                        overflow: "hidden",
                        borderRadius: "13px",
                        width: "250px",
                        height: "83px",
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                        <img src={AppStore} alt="Download on the App Store" 
                            style={{
                                borderRadius: "13px",
                                width: "250px",
                                height: "83px"
                            }}
                        />    
                </a>
                </div>
            </div>
        </Section>

        <Section
            layout={LayoutType.columns}    
        >
            <div className={styles.sectionImageContainer}><img src={Image1} className={styles.sectionImage}  alt={""}/></div>
            <div className={styles.sectionImageContainer}><img src={Image2} className={styles.sectionImage}  alt={""}/></div>
            <div className={styles.sectionImageContainer}><img src={Image3} className={styles.sectionImage}  alt={""}/></div>
            
        </Section>
        <Section
            layout={LayoutType.columns}    
        >
            <div className={styles.sectionImageContainer}><img src={WatchImage1} className={styles.sectionImage}  alt={""}/></div>
            <div className={styles.sectionImageContainer}><img src={WatchImage2} className={styles.sectionImage}  alt={""}/></div>
            <div className={styles.sectionImageContainer}><img src={WatchImage3} className={styles.sectionImage}  alt={""}/></div>
            
        </Section>
        <Section
            layout={LayoutType.right}
            background={colors.primary}
            color={colors.white}
        >
            <div>
                <h1>Build healthier habits with Habbie as your little sidekick</h1>
                <p>
                Habbie will motivate you by giving you gifts when you hit your fitness goals, or by leaving an unwanted surprise if you don't!
                </p>
                <p>
                The concept is simple: you can feed your Habbie Pet by hitting your daily fitness goals. The more you achieve, the happier and healthier your pet becomes. It's a win-win situation - you get fit, and your Habbie Pet thrives!
                </p>
                
            </div>
        </Section>
        <Section
            layout={LayoutType.left}    
        >
            <div className={styles.sectionImageContainer}><img src={Image4} className={styles.sectionImage} alt={""} /></div>
            <div style={{width: '100%'}}>
                <h1>
                    Key Features:
                </h1>
                <p>
                    <ul>
                        <li>Adorable Virtual Pet: Watch your Habbie grow, play, and express emotions as you achieve your daily fitness goals.</li>

                        <li>Stay Active: Habbie's encourages you to be more active and healthier by turning your daily fitness into a rewarding experience.</li>

                        <li>Track Progress: Monitor your goals, achievements, and pet's health with easy-to-read statistics.</li>
                    </ul>
                </p>
            </div>
        </Section>
        <Section
            background={colors.primary}
            color={colors.white}
        >
            <h1>FAQ</h1>
            <SectionDropdown
                title={"What is Habbie?"}
                description={<div className={styles.sectionDropdownInner}>
                    <p>
                        Habbie  is a unique fitness app that introduces a virtual pet to your watch and phone homescreen. You can feed and care for your virtual pet by achieving your daily fitness goals.
                    </p>
                </div>    
            }
            />
            <SectionDropdown
                title={"How do I get one?"}
                description={<div className={styles.sectionDropdownInner}>
                        <p>
                            Simply download the Habbie app from your device's app store, follow the setup instructions, and customize your virtual pet. It's easy to get started on your journey to a healthier lifestyle.
                        </p>
                    </div>    
                }
            />
            <SectionDropdown
                title={"How do I feed my Habbie?"}
                description={<div className={styles.sectionDropdownInner}>
                        <p>
                            You feed your Habbie by achieving your fitness goals. The more you achieve, the happier and healthier your pet becomes. It's a fun and motivating way to stay active.
                        </p>
                    </div>    
                }
            />
            <SectionDropdown
                title={"What Habit types can I track?"}
                description={<div className={styles.sectionDropdownInner}>
                        <p>
                        Habbie supports the following habit types:<br />
                        - Step Count<br />
                        - Running/Walking Distance<br />
                        - Cycling Distance<br />
                        - Wheelchair Pushes<br />
                        - Wheelchair Distance<br />
                        - Swimming Stroke Count<br />
                        - Swimming Distance
                        </p>
                    </div>    
                }
            />
            
            

        </Section>
        <Section
            layout={LayoutType.columns}    
        >
            <p>If you have any more questions or need further assistance, feel free to contact our support team through the app. We're here to help you on your journey to a healthier and happier lifestyle with your Habbie!</p>
            
        </Section>
    </>
  );
}