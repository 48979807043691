import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Home from './home/home';
import { Skeleton } from './ui/skeleton';
import Privacy from './home/privacy';
import {Guides} from './home/guides';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ItemsPage } from './pages/world/items/itemsPage';
import { WorldPage } from './pages/world/worldPage';

const firebaseConfig = {
  apiKey: "AIzaSyC_DU5ZbLxpQ72mYFu69zUfkSsnD25stPI",
  authDomain: "habbie-website.firebaseapp.com",
  projectId: "habbie-website",
  storageBucket: "habbie-website.appspot.com",
  messagingSenderId: "220271658564",
  appId: "1:220271658564:web:28365a9f8fbdd229309e11",
  measurementId: "G-ZYSBT4QERR"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Skeleton />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: () => {
          logEvent(analytics, "page_home")
          return {}
        },
        errorElement: <ErrorPage />,
      },
      {
        path: "privacy",
        loader: () => {
          logEvent(analytics, "page_privacy")
          return {}
        },
        element: <Privacy />,
        errorElement: <ErrorPage />,
      },
      {
        path: "guides",
        loader: () => {
          logEvent(analytics, "page_guides")
          return {}
        },
        element: <Guides />,
        errorElement: <ErrorPage />,
      },
      {
        path: "items",
        loader: () => {
          logEvent(analytics, "page_items")
          return {}
        },
        element: <ItemsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "world",
        loader: () => {
          logEvent(analytics, "page_world")
          return {}
        },
        element: <WorldPage />,
        errorElement: <ErrorPage />,
      },
    ]
  },
  {
    path: "/app/guides/:systemMode",
    element: <Guides />,
    errorElement: <ErrorPage />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
