import Markdown from 'react-markdown'
import './guides.css';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import source from "./guides/guides.md";


export const Guides = () => {
    const [markdown, setMarkdown] = useState<string>("");
    let { systemMode } = useParams();
    

    useEffect(() => {
        if (systemMode === "dark") {
            document.querySelector('html')?.classList.add('dark')
        } else {
            document.querySelector('html')?.classList.remove('dark')
        }
    }, [systemMode]) 

    
    useEffect(() => {
        fetch(source).then((response) => response.text()).then((text) => {
            setMarkdown(text);
        });
    }, [])

  return (
        <div className={`container ${systemMode === "dark" ? "dark" : undefined} markdown`}>
            <div className={"containerInner"}>
                <Markdown>{markdown}</Markdown>
            </div>
        </div>
  );
}