import { Link, Outlet } from 'react-router-dom';
import styles from './skeleton.module.css';

export const Skeleton = () => {
  return (
    <div className={styles.pageContainer}>
        <div className={styles.navContainer}>
            <Link to="/" className={styles.navItem}>Home</Link>
            <Link to="/guides" className={styles.navItem}>Guides</Link>
            <Link to="/world" className={styles.navItem}>World</Link>
            <Link to="/privacy" className={styles.navItem}>Privacy Policy</Link>
        </div>
        <div className={styles.bodyContainer}>
          <Outlet />
        </div>
        <div className={styles.footerContainer}>
            &copy; Selexin 2024
        </div>
    </div>
  );
}