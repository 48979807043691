import styles from './worldPage.module.css';
import { useParams } from "react-router-dom";
import { useEffect } from "react";


export const WorldPage = () => {
    let { systemMode } = useParams();
    

    useEffect(() => {
        if (systemMode === "dark") {
            document.querySelector('html')?.classList.add('dark')
        } else {
            document.querySelector('html')?.classList.remove('dark')
        }
    }, [systemMode]) 

   
  return (
        <div className={`container ${systemMode === "dark" ? styles.dark : undefined} ${styles.markdown}`}>
            <div className={styles.containerInner}>
            Habbie world coming soon...
                
            </div>
        </div>
  );
}
