export const colors = {
    black: '#151315',
    white: '#ffffff',
    // dark to light
    grey4: '#4f4f4f',
    grey3: '#292c33',
    grey2: '#424a53',
    grey1: '#646d77',

    white4: '#87a6ac',
    white3: '#add0d7',
    white2: '#cdeaef',
    white1: '#e4fbff',

    black4: "#b8b8b8",
    black3: "#a1a1a1",
    black2: "#5d5d5d",
    black1: "#353535",

    bg: '#ffffff',
    bgDark: '#bbb3bb',

    primary: '#5498FF',
    secondary: '#EA8941',


  }