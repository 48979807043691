import styles from './itemsPage.module.css';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import itemList from './itemList.json';


export const ItemsPage = () => {
    let { systemMode } = useParams();
    

    useEffect(() => {
        if (systemMode === "dark") {
            document.querySelector('html')?.classList.add('dark')
        } else {
            document.querySelector('html')?.classList.remove('dark')
        }
    }, [systemMode]) 

    console.log(itemList.items[0])

    const itemsRender = () => {
        return itemList.items.map(item => <ItemContainer
                title={item.name}
                details={item.rarity.toString()}
                image={""}

            />)
    }
  return (
        <div className={`container ${systemMode === "dark" ? styles.dark : undefined} ${styles.markdown}`}>
            <div className={styles.containerInner}>
            <h1>Rarity</h1>
            <div className={styles.rarityTable}>
                <div className={styles.rarityTableRow}>
                    <h2>Common</h2>
                    Commonly restocked items through daily gifts
                </div>
            </div>
                
                <h2>Uncommon</h2>
                Items available through daily gifts, but in lower frequency and less quantity.
                <h2>Rare</h2>
                Items occasionally available through daily gifts, but in lower frequency and less quantity.
                <h2>Ultra Rare</h2>
                Items only released in very small quantities and through special events like the shop. On very special occasions you might get one in your daily present.
                <h2>Special</h2>
                Items never available through daily gifts, only through special events.
                <h2>Retired</h2>
                No longer in circulation - only available through trade
                <h1>Items</h1>
                {itemsRender()}
                
            </div>
        </div>
  );
}

const ItemContainer = ({title, details, image}: {title: string, details: string, image: string}) => {
    return  <div className={styles.itemContainer}>
         <div className={styles.itemImage}>
            <img className={styles.itemImage} src={image} alt="" />
        </div>
         <div className={styles.itemTitle}>
            {title}
        </div>
        <div className={styles.itemDetails}>
            {details}
        </div>

    </div>
}